.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.LineTimeBackground{
  padding-left: 10px;
}

/*BABYFRESHreshStyles*/

.BABYFRESH .TipoLetraTitulo {
  font-family: 'Bariol-Bold';
  font-weight: bolder;
}

.BABYFRESH .TipoLetraTexto {
  font-family: 'Bariol-Regular';
}

.BABYFRESH .Color .Desactivado {
  color: #838485;
}

.BABYFRESH .Color .Activado {
  color: #88b7ea;
}

.BABYFRESH .BackgroundColor {
  background-color: #88b7ea;
}

.BABYFRESH .BackgroundImage {
  background-image: url(./Img/BF\ BLANCO.png);
  min-height: 90px;
  background-position: center;
  background-repeat: no-repeat;

}



.BABYFRESH .LineTimeBackground .Activado{
  background-color: #dcf8fa;
}

.LineTimeBackground .Desactivado{
  background-color: #a8a8a8;
}

/*GEF*/

.GEF .BackgroundColor {
  background-color: #000000;
}

.GEF .Color .Desactivado {
  color: #838485;
}

.GEF .TipoLetraTitulo {
  font-family: 'Bariol-Bold';
  font-weight: bolder;
}

.GEF .TipoLetraTexto {
  font-family: 'Pangram-Regular';
}

.GEF .Color .Activado {
  color: rgb(15, 175, 15);
}

.GEF .BackgroundImage {
  background-image: url(./Img/GEF\ CORPORATIVO.png);
  min-height: 90px;
  background-position: center;
  background-repeat: no-repeat;

}

.GEF .LineTimeBackground .Activado{
  background-color: #cdffd0;
}

.LineTimeBackground .Desactivado{
  background-color: #a8a8a8;
}

/*PUNTOBLANCO Styles*/

.PUNTOBLANCO .BackgroundColor {
  background-color: #000000;
}

.PUNTOBLANCO .Color .Desactivado {
  color: #838485;
}

.PUNTOBLANCO .TipoLetraTitulo {
  font-family: 'Bariol-Bold';
  font-weight: bolder;
}

.PUNTOBLANCO .TipoLetraTexto {
  font-family: 'Gotham-Medium';
}

.PUNTOBLANCO .Color .Activado {
  color: #000000;
}

.PUNTOBLANCO .TdP .Activado {
  font-family: 'Gotham-Medium';
}

.PUNTOBLANCO .BackgroundImage {
  background-image: url(./Img/PUNTO\ BLANCO\ CORPORATIVO.png);
  min-height: 90px;
  background-position: center;
  background-repeat: no-repeat;

}

.PUNTOBLANCO .LineTimeBackground .Activado{
  background-color: #92a794;
}

.LineTimeBackground .Desactivado{
  background-color: #a8a8a8;
}

/*GALAX*/

.GALAX .BackgroundColor {
  background-color: #ff4d00;
}

.GALAX .Color .Desactivado {
  color: #838485;
}

.GALAX .TipoLetraTitulo {
  font-family: 'Bariol-Bold';
  font-weight: bolder;
}

.GALAX .TipoLetraTexto {
  font-family: 'Poppins-Regular';
}

.GALAX .Color .Activado {
  color: #ff4d00;
}

.GALAX .BackgroundImage {
  background-image: url(./Img/GALAX\ BLANCO.png);
  min-height: 90px;
  background-position: center;
  background-repeat: no-repeat;

}

/*Entrega estimada diesño*/

.GALAX .LineTimeBackground .Activado{
  background-color: #fcc8b0;
}

.TituloEntregaEstimada{
  color: white;
  width: 40%;
  margin-left: 30%;
  margin-top: 20px;
  border-top-right-radius: 1vmax;
  border-top-left-radius: 1vmax;
  border-bottom-right-radius: 1vmax;
  border-bottom-left-radius: 1vmax;
  height: 40px;
  font-weight: 700;
}

@media (max-width:914px) {
  .TituloEntregaEstimada{
    width: 60%;
    margin-left: 20%;
  }
}

@media (max-width:400px) {
  .TituloEntregaEstimada{
    width: 70%;
    margin-left: 15%;
  }
}

@media (max-width:320px) {
  .TituloEntregaEstimada{
    width: 90%;
    margin-left: 5%;
  }
}