.contenedorEstado{
    width: 100%;
    display: flex;
    text-align: left;
    padding-left: 10px;
}

.titulo{
    font-weight: bolder;
}

.icono{
    width: 20px;
}

.info{
    width: 90%;
    margin-top: 60px;
}

@media (max-width:661px) {
    .info{
        margin-top: 75px;
    }
}

@media (max-width:515px) {
    .info{
        margin-top: 95px;
    }
}
