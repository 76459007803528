.posicionIcono{
    margin-top: 60px;
    
}

@media (max-width:661px) {
    .posicionIcono{
        margin-top: 75px;
    }
}

@media (max-width:515px) {
    .posicionIcono{
        margin-top: 95px;
    }
}
