.contenedor{
    width: 100%;
    height: auto;
}

.imagenLogo{
    width: auto;

}

.logoBabyFresh{
    width: 100%;
  }
  
  .logoGef{
    width: auto;
  }
  
  .logoGalax{
    width: auto;
  }
  
  .logoPuntoBlanco{
    width: auto;
  }
  
  .logoParfois{
    width: 60%;
  }

@media (min-width:1000px){
  .logoBabyFresh{
    width: fit-content;
  }
}