.TextoColor {
    color: white;
}

.Contenedor {
    margin-top: 20px;
    width: 60%;
    margin-left: 20%;
    border-top-right-radius: 1vmax;
    border-top-left-radius: 1vmax;
    border-bottom-right-radius: 1vmax;
    border-bottom-left-radius: 1vmax;
}

.TituloGrosor {
    font-weight: bold;
    margin-top: 1%;
    font-size: 20px;
}

.SubtituloGrosor {
    font-weight: 700;
}

.ContenedorInfo {
    margin-top: 3%;
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
}

.ContenedorNumeroOrder {
    width: 45%;
    display: flex;
}

.ContenedorDireccion {
    width: 45%;
    display: flex;
}

.direccion{
    padding-left: 10px;
    padding-right: 10px;
}

.codigo{
    padding-left: 10px;
    padding-right: 10px;
    max-width: fit-content;
}

@media (max-width:1216px) {
    .ContenedorNumeroOrder{
        display: grid;
        text-align: left;
        width: 50%;
        padding-left: 10px;
    }
    .codigo{
        padding-left: 0;
    }
}

@media (max-width:914px){
    .Contenedor{
        width: 80%;
        margin-left: 10%;
    }
}

@media (max-width:610px) {

    .ContenedorNumeroOrder{
        padding-left: 0;
    }

    .ContenedorDireccion{
        display: grid;
        text-align: left;
        width: 100%;
    }

    .direccion{
        padding-left: 0;
    }

    .ContenedorInfo{
        display: grid;
    }
}

@media (max-width:337px) {
    .Contenedor{
        width: 90%;
        margin-left: 5%;
    }
}
