.contenedor {
    display: flex;
    justify-content: center;
    width: 34%;
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-top: none;
    margin: auto;
    margin-bottom: 5%;
    border-radius: 0 0 10px 10px;
    padding: 4% 0%;
}

.contendorEstado {
    display: flex;
    width: 100%;
    height: 150px;
    justify-content: center;
    text-align: center;
}


.estado {
    display: grid;
    font-size: 18px;
}

.contenedorCirculo {
    justify-content: center;
    display: flex;
    height: 150px;
    width: 30px;
    
}

.borderFirst{
    border-radius:25px 25px 0 0;
}

.borderLast{
    border-radius:0px 0px 25px 25px;
}

.contenedorFlex {
    display: flex;
}

@media (max-width:661px) {
    .contendorEstado{
        height: 180px;
    }
    .contenedorCirculo{
        height: 180px;
    }
}



@media (max-width:515px) {
    .contendorEstado{
        height: 220px;
    }
    .contenedorCirculo{
        height: 220px;
    }
}

@media (max-width:914px) {
    .contenedor{
        width: 55%;
    }
}

@media (max-width:320px) {
    .contenedor{
        width: 85%;
    }
}